<template>
  <m-error code="403" message="抱歉，你无权访问该页面" :error-img="image403" :link="link"></m-error>
</template>

<script>
import errorTemplate from "@/components/error/Index";
import image403 from "@/assets/images/error/403.svg";

export default {
  data() {
    return {
      image403,
      link: {
        text: "返回登录页",
        url: "/"
      }
    };
  },
  components: {
    "m-error": errorTemplate
  }
};
</script>
